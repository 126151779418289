<!-- 创建商品 -->
<template>
	<div class="bg-white px-3 goods_create" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<router-link :to="{name:'shop_goods_list'}" style="position: absolute;top: 12px;left: 200px;">
			<el-button size="mini">回到商品列表</el-button>
		</router-link>


		<el-form ref="form" label-width="80px">
			<el-form-item label="标题挂图">
				<el-radio-group v-model="radio" style="padding-top: 9px;">
				    <el-radio :label="1">无</el-radio>
				    <el-radio :label="2">上新</el-radio>
				    <el-radio :label="3">热卖</el-radio>
					<el-radio :label="4">活动</el-radio>
					<el-radio :label="5">火爆</el-radio>
				</el-radio-group>
			</el-form-item>
			
			<el-form-item label="商品名称" >
				<el-input v-model="form.title" placeholder="请输入商品名称，不能超过60个字符" class="w-50"></el-input>
			</el-form-item>
			
			<el-form-item label="封面">
				<div>
					<span v-if="!form.cover" class="btn btn-light border mr-2" @click="chooseImage">
						<i class="el-icon-plus"></i>
					</span>
					<img v-else :src="form.cover" class="rounded" style="width: 45px;height: 45px;cursor: pointer;" @click="chooseImage" />
				</div>
			</el-form-item>
			
			<el-form-item label="商品分类">
				<el-select v-model="form.category_id" placeholder="请选择商品分类">
					<el-option :label="item | tree" v-for="(item,index) in cates" :key="index" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item label="商品描述">
				<el-input type="textarea" v-model="form.desc" placeholder="选填，商品卖点简述，例如：此款商品美观大方 性价比较高 不容错过" class="w-50"></el-input>
			</el-form-item>
			
			<el-form-item label="商品单位">
				<el-input v-model="form.unit" placeholder="请输入商品单位" class="w-50"></el-input>
			</el-form-item>
			
			<el-form-item label="总库存">
				<el-input type="number" v-model="form.stock" class="w-25">
					<template slot="append">件</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="库存预警">
				<el-input type="number" v-model="form.min_stock" class="w-25">
					<template slot="append">件</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="销售价">
				<el-input type="number" v-model="form.min_price" class="w-25">
					<template slot="append">元</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="原价">
				<el-input type="number" v-model="form.min_oprice" class="w-25">
					<template slot="append">元</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="分销佣金">
				<el-input type="number" v-model="form.commission" class="w-25">
					<template slot="append">元</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="库存显示">
				<el-radio-group v-model="form.stock_display">
					<el-radio :label="1" border>是</el-radio>
					<el-radio :label="0" border>否</el-radio>
				</el-radio-group>
			</el-form-item>
			
			<el-form-item label="是否上架">
				<el-radio-group v-model="form.status">
					<el-radio :label="0" border>放入仓库</el-radio>
					<el-radio :label="1" border>立即上架</el-radio>
				</el-radio-group>
			</el-form-item>
			
			<!-- <el-form-item label="运费模板">
				<el-select v-model="form.express_id" placeholder="请选择运费模板">
					<el-option :label="item.name" v-for="(item,index) in express" :key="index" :value="item.id"></el-option>
				</el-select>
			</el-form-item>	 -->
			
		</el-form>
		<el-button type="primary" style="position: fixed;bottom: 50px;right: 50px;" @click="submit">提交</el-button>

	</div>
</template>

<script>
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				radio: 1,
				id: 0,
				cates: [],
				express: [],
				form: {
					title: "", //商品名称
					category_id: "", //商品分类
					cover: "", //商品封面
					desc: "", //商品描述
					unit: "", //商品单位
					stock: "", //总库存
					min_stock: "", //库存预警
					ischeck: 0, //是否审核 0待处理 1通过 2拒绝
					status: 0, //是否上架 0仓库 1上架
					stock_display: 0, //库存显示 0隐藏 1禁用
					express_id: 1, //运费模板
					min_price: "", //最低销售价
					min_oprice: "", //最低原价
					commission: "", // 佣金
					mamager_id: "", // 商家id
					srcs:"",
				}
			}
		},
		computed: {
			// 引入store-modules的user模块里面的user(存储用户相关的数据)
			...mapState({
				user: state => state.user.user,
				navBar: state => state.menu.navBar
			}),
		},
		// 过滤器 商品无限级分类
		filters: {
			tree(item) {
				if (item.level == 0) {
					return item.name
				}
				let str = ''
				for (let i = 0; i < item.level; i++) {
					str += i == 0 ? '|--' : '--'
				}
				return str + ' ' + item.name;
			}
		},
		// 生命周期
		created() {
			// 获取id
			this.id = this.$route.params.id //拿到当前商品id
			if (this.id) {
				// 获取之前的商品详情
				this.layout.showLoading()
				this.axios.get('/admin/goods/read/' + this.id, { //获取之前的商品详情 的api
					token: true
				}).then(res => {
					let data = res.data.data
					this.form = data
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			}

			// 获取商品分类api
			this.axios.get('/admin/goods/create', {
				token: true
			}).then(res => {
				let data = res.data.data
				this.cates = data.cates
				// this.express = data.express.list
			})
		},
		methods: {
			chooseImage() {
				this.app.chooseImage((res) => {
					this.form.cover = res[0].url
				}, 1)
			},
			//提交
			submit() {
				if(this.radio == 2){
					this.form.srcs = 'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/62412b9a9e8c3.png'
				}else if(this.radio == 3){
					this.form.srcs = 'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/62412b9a9c02e.png'
				}else if(this.radio == 4){
					this.form.srcs = 'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/62412b9a9d47f.png'
				}else if(this.radio == 5){
					this.form.srcs = 'http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/62412b9a9fbd6.png'
				}else{
					this.form.srcs = ''
				}
				this.layout.showLoading()
				let url = this.id ? '/admin/goods/' + this.id : '/admin/goods'
				this.form.mamager_id = this.form.mamager_id ? this.form.mamager_id : this.user.id
				this.axios.post(url, this.form, {
					token: true
				}).then(res => {
					this.$message({
						type: "success",
						message: this.id ? "修改成功" : "发布成功" //如果this.id存在,就是修改成功,否则就是发布成功
					})
					this.$router.push({
						name: "shop_goods_list"
					})
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
	}
</script>

<style>
	.goods_create .el-form-item {
		margin-bottom: 15px;
	}
</style>
